@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Ano";
  src: url("./assets/font/Anonymous_Pro/AnonymousPro-Regular.ttf");
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
body {
  background-color: black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.react-slideshow-container .default-nav {
  background: none !important;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background: rgba(240, 241, 241, 0.8);
}
.pos-center {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.loader {
  border: 5px solid transparent;
  border-top: 5px solid rgba(126, 129, 128, 1);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
  z-index: 1000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.custom-scrollbar::-webkit-scrollbar {
  /* Customize the scrollbar width */
  width: 8px;
  background: blue;
}

.custom-scrollbar::-webkit-scrollbar-track {
  width: 8px;
  /* Customize the scrollbar track */
  background: #676767;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  width: 8px;
  border-radius: 10px;
  background: #979797;
  /* Customize the scrollbar thumb */
}

.scroll-padding {
  padding-right: 10px; /* Adjust as needed */
}

.progressbar::-webkit-scrollbar {
  width: 10px;
}

.progressbar::-webkit-scrollbar-track {
  background: #676767;
  border-radius: 10px;
}

.progressbar::-webkit-scrollbar-thumb {
  background: #4F99F0;
  border-radius: 10px;
}

.progressbar::-webkit-scrollbar-thumb:hover {
  background: #3b82f6; /* Slightly lighter blue on hover */
}

.hover-effect:hover {
  color: #F9F41F !important; /* Blue color for text */
}

.hover-effect:hover svg {
  stroke: #F9F41F !important; /* Blue color for SVG icon */
}


.Collapsible {
  padding: 10px 10px;
  border-bottom: 1px solid #2C2C2C;
}
.form-select {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLWRvd24iIGZpbGw9Im5vbmUiIGhlaWdodD0iMjQiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cG9seWxpbmUgcG9pbnRzPSI2IDkgMTIgMTUgMTggOSIvPjwvc3ZnPg==');
  background-size: 1.5rem;
  background-position: right 1.7rem center;
}